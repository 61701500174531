import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { getHeight, getWidth } from './screenBounds';
import { pages, workParams } from '../const';

import './home.css';
import Div100vh from 'react-div-100vh'

import centerLogoVideo2 from './assets/LOGOfin3.mp4';
import topLogoImg from './assets/logo.gif';
import {ModalAbout} from "../components/ModalAbout";
import {SocialLinks} from "../components/SocialLinks";
import {ModalContacts} from "../components/ModalContacts";

const NavItem = ({ cssPrefix, label, onClick, onMouseEnter, onMouseLeave, children }) => {
    const [hovered, setIsHovered] = useState('');
    return <div
        className={`nav-item nav-item-${cssPrefix} ${hovered}`}>

        <div className='nav-work-item'
            onMouseEnter={() => {
                setIsHovered('nav-item-hovered');
                if (onMouseEnter) onMouseEnter();
            }}
            onMouseLeave={() => {
                setIsHovered('nav-item-faded');
                if (onMouseLeave) onMouseLeave();
            }}
            >
            <span className='nav-item-label nav-item-label__desktop' onClick={onClick}>{label}</span>
            {children}
        </div>
    </div>
};

const CenterLogo = () => {
    const centerLogo = useRef(null);

    useEffect(() => {
        const handleWindowMouseMove = event => {
            if (!centerLogo || !centerLogo.current) {
                return;
            }

            const pos = {
                x: event.screenX,
                y: event.screenY,

                w: getWidth(),
                h: getHeight(),
            };

            const videoDuration = centerLogo.current.duration;
            const relativePos = ((pos.x / pos.w) - 0.5) * 1.4; // -0.5 - 0.5
            const res =
                relativePos >= 0
                    ? relativePos * videoDuration
                    : videoDuration + relativePos * videoDuration;

            if (!Number.isFinite(res)) return;
            centerLogo.current.currentTime = res;
        };


        if (window.matchMedia('(max-width:1024px)').matches){
            console.log(centerLogo.current.currentTime);
            centerLogo.current.play();
        }else {
            window.addEventListener('mousemove', handleWindowMouseMove);

            return () => {
                window.removeEventListener('mousemove', handleWindowMouseMove);
            };
        }
    });

    return <div className='center-logo-container'>
        {/*<video ref={centerLogo} src={centerLogoVideo} />*/}

        <video ref={centerLogo} className='center-logo' muted loop playsInline='off'>
            <source src={centerLogoVideo2} type="video/mp4"/>
            {/*<source src={centerLogoVideo_webm} type="video/webm"/>*/}
        </video>
    </div>;
};

const WorkSubitems = ({ isVisible }) => {
    const navigate = useNavigate();
    const items = [
        { text: 'Music Video', link: `${pages.work}?topic=${workParams.musicVideo}` },
        { text: 'Commercial', link: `${pages.work}?topic=${workParams.commercial}` },
        { text: 'Reel', link: `${pages.work}?topic=${workParams.reel}` },
    ];
    const [renderCount, setInitialShown] = useState(0);
    useEffect(() => {
        setInitialShown(renderCount + 1);
    }, [isVisible]);

    return <ul className={`nav-subitems ${(isVisible) ? 'active' : ''}`}>
        {items.map(({ text, link }, n) =>
            <li
                key={text}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(link);
                }}
            >
                <p style={{transitionDelay: `${n * 150}ms`}}>{text}</p>
            </li>)}
    </ul>;
};

export const Modal = ({ isVisible, hideModal, withoutFade, crossHidden, children, className }) => (
    <div style={{ display: isVisible ? 'block' : 'none' }} onClick={() => hideModal()} className={className}>
        <div className={`modal-container ${!withoutFade ? 'modal-container-fade' : ''}`}>
            {!crossHidden && <div className="modal-header">
                <button onClick={hideModal}>X</button>
            </div>}
            <div className='modal-content'>
                {children}
            </div>
        </div>
    </div>
)

export const Logo = () => {
    const navigate = useNavigate();
    return(
        <NavItem cssPrefix='top-left nav-logo-top' label={<div className='nav-logo' onClick={() => navigate('/')}>
            <img src={topLogoImg} alt='logo' />IMIA</div>
        }/>
    )
};

export const Copyright = () => <p className="copyright-text">
    © Copyright {new Date().getFullYear()} HIMIA.
</p>;


export const HomePage = () => {
    const [aboutModalVisible, setAboutModalVisibility] = useState(false);
    const [contactsModalVisible, setContactModalVisibility] = useState(false);
    const [workSubitemsVisible, setWorkSubitemsVisibility] = useState(false);

    const navigate = useNavigate();

    return <Div100vh className='page-container home'>
        <Logo />
        <div />
        <NavItem cssPrefix='top-right'
                 label="Work"
                 onClick={() => navigate(pages.work)}
                 onMouseEnter={() => setWorkSubitemsVisibility(true)}
                 onMouseLeave={() => setWorkSubitemsVisibility(false)}
        >
            <WorkSubitems isVisible={workSubitemsVisible} />
        </NavItem>

        <CenterLogo />

        <NavItem cssPrefix='bottom-left' label="Contact" onClick={() => setContactModalVisibility(true)} />
        <SocialLinks />
        <NavItem cssPrefix='bottom-right' label="About" onClick={() => setAboutModalVisibility(true)} />

        <ModalAbout
            isVisible={aboutModalVisible}
            hideModal={() => setAboutModalVisibility(false)}
        />
        <ModalContacts
            isVisible={contactsModalVisible}
            hideModal={() => setContactModalVisibility(false)}
        />

        <footer>
            <div className='work-footer'>
                <Copyright />
            </div>
        </footer>
        <div
            onClick={() => navigate(pages.work)}
            className="mob-screen">
            <video muted loop playsInline='on' autoPlay>
                <source src={centerLogoVideo2} type="video/mp4"/>
            </video>
        </div>

    </Div100vh>
};
