import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { pages, workParams } from '../const';
import { Logo, Modal, Copyright } from '../Home';

import playIcon from './play.png';
import './work.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {ModalAbout} from "../components/ModalAbout";
import {ModalContacts} from "../components/ModalContacts";
import Div100vh from "react-div-100vh";
import useVH from "react-vh";

const contentIds = [
    { id: 460465859, category: workParams.commercial, title: 'Title project #1', desc: 'VFX, Animation' },
    { id: 447633053, category: workParams.commercial, title: 'Title project #2', desc: 'VFX' },
    { id: 452023313, category: workParams.commercial, title: 'Title project #3', desc: 'Music, Animation' },

    { id: 783453158, category: workParams.musicVideo, title: 'Title project #4', desc: 'VFX, Animation' },
    { id: 783453158, category: workParams.musicVideo, title: 'Title project #5', desc: 'Animation' },
    { id: 783453158, category: workParams.musicVideo, title: 'Title project #6', desc: 'VFX, Animation' },

    { id: 71507991, category: workParams.reel, title: 'Title project #7', desc: 'VFX, Animation' },
    { id: 783453158, category: workParams.reel, title: 'Title project #8', desc: 'VFX, Animation' },
    { id: 783453158, category: workParams.reel, title: 'Title project #9', desc: 'VFX, Animation' },
];

const WorkHeader = ({ topic, setTopic, showModalAbout, showModalContact, hideModalAbout, hideModalContact }) => {
    const [hiddenMenuActive, setHiddenMenuActive] = useState(false);

    const navigate = useNavigate();
    const go = topic => {
        const topicType = Object.keys(workParams)
            .find(t => topic === workParams[t])
            ?? null;
        setTopic(topicType);
        if (topic) {
            navigate(`${pages.work}?topic=${workParams[topicType]}`);
        } else {
            navigate(`${pages.work}`);
        }
    };

    const handlerBurgerClick = () => {
        setHiddenMenuActive(!hiddenMenuActive);
        hideModalAbout();
        hideModalContact();
    }

    const handlerWorkClick = (click) => {
        click();
        hideModalAbout();
        hideModalContact();
    }

    return (
        <>
            <nav className='work-header'>
                <Logo />
                <div className={`work-header-items`}>
                    <div onClick={() => handlerWorkClick(() => go())}
                         className={`work-header-item ${!topic ? 'work-header-item-selected' : ''}`}>
                        All</div>
                    <div className='work-header-item-separator'>|</div>
                    <div onClick={() => handlerWorkClick(() => go(workParams.musicVideo))}
                         className={`work-header-item ${topic === workParams.musicVideo ? 'work-header-item-selected' : ''}`}>
                        Music Video</div>
                    <div className='work-header-item-separator'>|</div>
                    <div onClick={() => handlerWorkClick(() => go(workParams.commercial))}
                         className={`work-header-item ${topic === workParams.commercial ? 'work-header-item-selected' : ''}`}>
                        Commercial</div>
                    <div className='work-header-item-separator'>|</div>
                    <div onClick={() => handlerWorkClick(() => go(workParams.reel))}
                         className={`work-header-item ${topic === workParams.reel ? 'work-header-item-selected' : ''}`}>
                        Reel</div>
                </div>
                <div className={`burger ${hiddenMenuActive ? 'active' : ''}`} onClick={handlerBurgerClick}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </nav>
            <div className={`hidden-menu modal-container-fade ${hiddenMenuActive ? 'active' : '' }`}
                 onClick={() => setHiddenMenuActive(!hiddenMenuActive)}
            >
                <div className="hidden-menu__content">
                    <p onClick={() => go()}>Work</p>
                    <p onClick={showModalAbout}>About</p>
                    <p onClick={showModalContact}>Contact</p>
                </div>
            </div>
        </>
    )
};
const WorkContent = ({ topic }) => {
    const [contentHeight, setContentHeight] = useState(0);
    const [modalItem, setModalItem] = useState(null);
    const [hovered, setHovered] = useState(-1);
    const [contentDetails, setDetails] = useState([]);
    const [videoModalVisible, setVideoModalVisibility] = useState(false);

    useEffect(() => {
        let height = window.innerHeight;
        setContentHeight(height);
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const detailsResponses =
                await Promise.all(contentIds
                    .filter(({ category }) => topic ? category === topic : true)
                    .map(({ id }) => fetch(`https://vimeo.com/api/v2/video/${id}.json`)));

            const responsesParsed =
                await Promise.all(detailsResponses.map(r => r.json()));
            setDetails(responsesParsed.reduce((acc, i) => ([...acc, ...i]), []));
        }
        fetchData();
    }, [topic])

    useEffect(() => {
        if (videoModalVisible){
            document.body.classList.add('hide-scroll');
        }else {
            document.body.classList.remove('hide-scroll');
        }
    }, [videoModalVisible]);


    const handlerVideoListItemClick = (id) => {
        const myObject = contentIds.find((obj) => obj.id === id);

        setModalItem(myObject);
        setVideoModalVisibility(true);
    }

    return (
        <div>
            <Scrollbars
                style={{height: contentHeight, maxHeight: contentHeight}}
                renderThumbVertical={props => < div {...props} className="thumb-vertical"/>}
                renderTrackVertical={props => < div {...props} className="track-vertical"/>}>
                <div className='work-content'>
                    {
                        contentDetails.map((c, i) =>
                            <div className='work-content-item' key={`content#${i}`}
                                 onMouseEnter={() => setHovered(i)}
                                 onMouseLeave={() => setHovered(-1)}
                                 onClick={() => handlerVideoListItemClick(c.id)}
                                // onClick={() => setModalItem(c.id)}
                            >
                                <img alt={c.title} src={c.thumbnail_large} />
                                <p className={i === hovered ? 'show-on-hover' : ''} >{c.title}</p>
                                <img className={`play-btn ${i === hovered ? 'show-on-hover' : ''}`} src={playIcon} alt="play" />
                            </div>
                        )}
                </div>
            </Scrollbars>
            {
                !!modalItem && <Modal
                    isVisible={videoModalVisible}
                    hideModal={() => setVideoModalVisibility(false)}
                    className='modal-video'
                >
                    <p className="project-title">{modalItem.title}</p>
                    <p className="project-desc">{modalItem.desc}</p>
                    <div className="iframe-wrapper">
                        <iframe title="vimeo video" src={`https://player.vimeo.com/video/${modalItem.id}`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>
                    </div>
                </Modal>
            }

        </div>

    )

}
const WorkFooter = () =>{
    return(
        <footer>
            <div className='work-footer'>
                <Copyright />
            </div>
        </footer>
    );
}



export const WorkPage = () => {
    useVH();
    const [aboutModalVisible, setAboutModalVisibility] = useState(false);
    const [contactsModalVisible, setContactModalVisibility] = useState(false);

    const location = useLocation();
    const topic = Object.keys(workParams)
        .find(t => location.search.includes(workParams[t]))
        ?? null;

    const [selectedTopic, setTopic] = useState(topic)

    return <div>
        <WorkHeader
            topic={workParams[selectedTopic]}
            setTopic={setTopic}
            showModalAbout={() => setAboutModalVisibility(true)}
            showModalContact={() => setContactModalVisibility(true)}
            hideModalAbout={() => setAboutModalVisibility(false)}
            hideModalContact={() => setContactModalVisibility(false)}
        />
        <WorkContent topic={workParams[selectedTopic]} />
        <WorkFooter />

        <ModalAbout
            isVisible={aboutModalVisible}
            hideModal={() => setAboutModalVisibility(false)}
        />
        <ModalContacts
            isVisible={contactsModalVisible}
            hideModal={() => setContactModalVisibility(false)}
        />

    </div>;
};
