import React, {useState} from "react";
import {Modal} from "../Home";
import {SocialLinks} from "./SocialLinks";

export const ModalContacts = ({isVisible, hideModal}) => {
    return (
        <Modal
            isVisible={isVisible}
            hideModal={hideModal}
            className='modal-contacts'
        >
            <a href="mailto:hello@himia.digital" className="modal-anim-link mail">hello@himia.digital</a>
            <ul>
                <li><a href="tel:+380957745972" className='modal-anim-link'>+380957745972</a></li>
                <li><a href="tel:+380637065191" className='modal-anim-link'>+380637065191</a></li>
            </ul>
            <SocialLinks/>
        </Modal>
    )
}
