import React, {useState} from "react";
import {Modal} from "../Home";

export const ModalAbout = ({isVisible, hideModal}) => {
    return (
        <Modal
            isVisible={isVisible}
            hideModal={hideModal}
            className='modal-about'
        >
            <div className="text">
                <p className='text-item'>
                    <strong>HIMIA</strong> is a VFX/Creative team.
                </p>
                <p className='text-item'>
                    Our path smoothly approaches decade and brought us here.
                    Our goal is to bring art and implement your ideas in a perfect way
                    with passion and taste.
                    Our "HIMIA" is a visionary team with experience in working
                    with named stars and brands.
                    Our job is to provide post-production services and solutions
                    to breathe life into your products.
                    Our professionalism, hardworking and dedication - are main tools
                    and the key for excelent result.
                </p>
                <div className='text-item'>
                    <p>
                        SERVICES:
                    </p>
                    <ul>
                        <li>-VFX</li>
                        <li>-CGI</li>
                        <li>-Concept Art</li>
                        <li>-CleanUp</li>
                    </ul>
                </div>
            </div>
            <div className="video">
                <iframe title="vimeo video" src="https://player.vimeo.com/video/783453158" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"/>
            </div>
        </Modal>
    )
}
