import {SocialIcon} from "react-social-icons";
import React from "react";

export const SocialLinks = () =>{
    return (
        <div className='social-links-container'>
            <div className='social-links'>
                <SocialIcon url="https://vimeo.com/himia" target="_blank" fgColor="#E6E6E8" bgColor='transparent'/>
                <SocialIcon url="https://instagram.com/himia" target="_blank" fgColor="#E6E6E8" bgColor='transparent' />
            </div>
        </div>
    );
}
