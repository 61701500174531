import { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import './App.css';

import { HomePage } from './Home';
import { WorkPage } from './Work';

const AppTransitions = () => {
  const [transitionStage, setTransitionStage] = useState("fadeIn");

  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);

  useEffect(() => {
    if (location.pathname !== displayLocation.pathname) setTransitionStage("fadeOut");
  }, [location, displayLocation]);

  return <div className={`App ${transitionStage}`}
    onAnimationEnd={() => {
      if (transitionStage === "fadeOut") {
        setTransitionStage("fadeIn");
        setDisplayLocation(location);
      }
    }}>
    <Routes location={displayLocation}>
      <Route path="/" element={<HomePage />} />
      <Route path="/work" element={<WorkPage />} />
    </Routes>
  </div>;
}

function App() {

  return (
    // <div className="App">
      <BrowserRouter>
        <AppTransitions />
      </BrowserRouter>
    // </div>
  );
}

export default App;
